export default {
  header: {
    navigation: {
      main: 'Main',
      reports: 'Reports'
    },
    notVerifiedList: {
      tooltip: 'Cameras requiring activation'
    }
  },
  noCameras: 'No Cameras',
  status: {
    camera: {
      ok: 'Performs properly',
      problem: 'Fault',
      notworking: 'Monitoring Control Off',
      unknown: 'Unknown'
    },
    exploitation: {
      introduced: 'Activated | Activated {date}',
      notVerified: 'Not activated | Not activated from {date}',
      notFilled: 'Not filled'
    }
  },
  buttons: {
    apply_reports: 'Create report',
    apply_main: 'Apply',
    goToCameras: 'Go to cameras',
    createReport: 'Create report',
    select: 'Select',
    selectAll: 'Select all',
    resetAll: 'Reset all',
    edit: 'Edit',
    save: 'Save',
    cancel: 'Cancel',
    refresh: 'Refresh',
    refreshList: 'Refresh list',
    yes: 'Yes',
    no: 'No',
    ok: 'OK',
    export: 'Export report',
    confrimExport: 'Export',
    auth: 'Login',
    clear: 'ОК',
    inExploitation: 'Monitoring Control On',
    outExploitation: 'Monitoring Control Off',
    exit: 'Exit',
    stop_loading: 'Stop',
    stop_export: 'Cancel export',
    fillCameraPassports: 'Fill camera passports',
    close: 'Close',
    openList: 'View more',
    closeList: 'View less',
    detailReport: 'Details by cameras'
  },
  placeholders: {
    login: 'Login',
    password: 'Password',
    rangeOfIpCameras: 'IP address range',
    statusCameras: 'Camera status',
    rangeOfIpServer: 'Server IP address',
    statusExpoitation: 'Camera Passport Status',
    search: 'Search',
    searchCameraByNumber: 'Enter camera name',
    timeInterval: 'Select interval',
    selectCamera: 'Select camera',
    cameraSearch: 'Search camera'
  },
  selectsOptions: {
    all: 'All',
    selectAll: 'Select all',
    timeInterval: {
      last_day: 'Last day',
      last_week: 'Last week',
      last_month: 'Last month',
      last_year: 'Last year',
      choose: 'Select interval'
    }
  },
  table: {
    emptyState: 'Loading data...',
    clearAllFilters: 'Clear filters'
  },
  timeIntervalModal: {
    title: 'Time Interval'
  },
  passportModal: {
    header: 'Camera parameters',
    formHeader: 'Camera passport',
    refresh: 'Refresh camera'
  },
  saveWithoutChangesModal: {
    question: 'Are you sure you want to close without saving?',
    description: 'Changes will not take effect'
  },
  saveModal: {
    title: 'Warning!',
    description: 'When you make edits to the Camera Passport, all previous camera statistics will be lost. Continue?'
  },
  fillPasportsModal: {
    title: 'Warning!',
    description: 'Among the selected cameras there are those for which there is already a completed Passport. If you continue, the parameters of such camera Passports will be changed. Continue?'
  },
  checkIntroducedCamerasModal: {
    title: 'Warning!',
    description: 'Among the selected cameras there are those for which statistics are already being collected. If you change the parameters of the passports of such cameras, all accumulated statistics on them will be lost. Continue?'
  },
  emptyPassportModal: {
    title: 'Warning!',
    description: 'Among the selected cameras there are those for which there is no completed passport. It is impossible to start collecting statistics for such cameras. Continue for the rest of the cameras?'
  },
  ignoreIntroducedCamerasModal: {
    title: 'Warning!',
    description: 'Among the selected cameras there are those for which statistics are already collected. The camera data will be ignored. Continue for the rest of the cameras?'
  },
  groupPassportModal: {
    title: 'Camera Passport Parameters',
    cameras: 'Cameras'
  },
  loaderModal: {
    editPassports: 'Camera Passports are being updated',
    updateStatistic: 'The collection of camera statistics is in process'
  },
  limitSeparateReportModal: {
    title: 'Number of cameras in the report: {count}.',
    description: 'Individual reports will be exported for the first {maxCount} cameras.'
  },
  editPassportsResults: {
    title: 'Result of updating Сamera Passport Parameters',
    total: 'Processed Passports',
    successful: 'Updated successfully',
    failed: 'Not updated'
  },
  editExploitationResults: {
    title: 'Result of starting Camera statistics collection',
    total: 'Selected cameras',
    ignored: 'Ignored cameras',
    successful: 'Monitoring Control successfully started',
    failed: 'Monitoring Control not started'
  },
  outexploitation: {
    title: 'Warning!',
    description: 'When you turn monitoring control off, all camera statistics will be lost. Continue?'
  },
  statistic: {
    totalCameras: 'Total cameras: {total}'
  },
  findedTotalCameras: 'Cameras found: {total} ',
  cameraPrams: {
    name: 'Camera name',
    status: 'Camera status',
    status_exploitation: 'Camera Passport Status',
    ip: 'Camera IP address',
    ip_server: 'Server IP address',
    passport: {
      fps: 'FPS',
      resolution: 'Resolution, px',
      kbps: 'Bitrate, kbps'
    }
  },
  detailTableReport: {
    header: 'Camera detail',
    name: 'Camera name',
    percent: 'Hours'
  },
  statisticsСommon: {
    titles: {
      notWorkingCameraCount: 'Number of cameras with monitoring control off',
      totalTime: 'Required number of hours of recording by cameras for all time'
    }
  },
  chart: {
    titles: {
      problemTime: 'Time spent by the cameras in "Fault" status',
      workingTime: 'Time spent by the cameras in "Performs properly" status',
      workingTimePercent: 'Time spent by the cameras in "Performs properly" status as a percentage of the required time',
      archiveTime: 'Total duration of the recorded video archive'
    },
    tootip: '{hours}h {minutes}m',
    timeAxis: 'Duration (hour)',
    percentAxis: 'Duration (percentage)',
    dayAxis: 'Days',
    forAllTime: 'For all time',
    summaryTime: '{hours}h {minutes}m',
    timeUnits: {
      millisecond: 'milliseconds',
      second: 'seconds',
      minute: 'minutes',
      hour: 'hours',
      day: 'days',
      week: 'weeks',
      month: 'months',
      quarter: 'quarters',
      year: 'years'
    },
    emptyChart: 'No data to display',
    detalizationEnd: 'Maximum graph scaling for selected time interval has been reached',
    unavailableDetalization: 'The current graph can not be scaled'
  },
  stubs: {
    startSearch: {
      title:'Report creation parameters are not set',
      description: 'Set the parameters and click the "Create report" button'
    },
    emptySearch: {
      title: 'No results were found for your search',
      description: 'Try to change your search settings and repeat'
    },
    noConnection: {
      title: 'No connection',
      description: 'Check the connection and refresh the page'
    },
    cameraImg: {
      notAvailable: 'Image not available'
    }
  },
  separate: {
    reports: 'Additionally export reports for each camera'
  },
  commonReport: {
    cameraCount: 'Cameras ({count})',
    selectInterval: 'Interval'
  },
  copyright: '© Intelligent Security Systems, {year}',
  fields: {
    login: 'Login',
    password: 'Password',
    timeInterval: 'Interval',
    startDate: 'Start date',
    endDate: 'End date'
  },
  validation: {
    required: 'The {_field_} field is required',
    valid_ip_range: 'Invalid IP address range',
    invalid_date: 'Invalid date',
    not_future_date: 'Date should not be in the future',
    dates_not_match: 'The end date must be at least one hour later than the start date',
    invalid_time_interval: 'The difference between the Start date and the End date must be not more than 5 years',
    resolution: 'Width and height must be filled in'
  },
  serverErrors: {
    '500': 'Internal server error',
    'time_out_error': 'Request timed out'
  },
  dateTrainslate: {
    rtl: false,
    yearSuffix: '',
    ymd: false,
    language: 'English',
    days: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    monthsAbbr: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  }
}